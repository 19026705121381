import React from "react";
import { Container } from "react-bootstrap";
import { Router, Link, Switch, Route } from "react-router-dom";
import Menu from "./Menu";
import Home from "./Home";
import CaseStudy from "./CaseStudy";
import Contact from "./Contact";
import NotFound from "./NotFound";
import history from "./history";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App: React.FC = () => {
  return (
    <Router history={history}>
      <div className="App">
        <Menu></Menu>
        <Switch>
          <Route path="/index.html" exact component={Home} />
          <Route path="/" exact component={Home} />
          <Route path="/case-study" component={CaseStudy} />
          <Route path="/contact" component={Contact} />
          <Route component={NotFound} />
        </Switch>
        <Container>
          &copy; {new Date().getFullYear()} Copyright:{" "}
          <Link className="SCPlink" to="/">
            CloudDevelopmentPartner.com
          </Link>
        </Container>
      </div>
    </Router>
  );
};

export default App;
