import React from "react";

const Home: React.FC = () => {
  return (
    <div className="container-fluid">
      <div>
        <div style={{ height: "20px" }}></div>
        <p>Please send all enquiries to</p>
        <a href="mailto:info@clouddevelopmentpartner.com">
          info@clouddevelopmentpartner.com
        </a>
      </div>
      <img className="img-fluid" src="./backgroundsm.png" alt="background" />
    </div>
  );
};

export default Home;
