import React from "react";

const Home: React.FC = () => {
  return (
    <div className="container-fluid">
      <div
        className="container-fluid"
        style={{ paddingRight: "10%", paddingLeft: "10%" }}
      >
        <div
          className="border"
          style={{
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            backgroundColor: "white"
          }}
        >
          <h3 className="text-center">Company Overview</h3>

          <p className="text-justify">
            <hr />
            Cloud Development Partner specialises in developing secure, robust,
            scaleable serverless infrastructure using Amazon Web Services. Code
            and infrastructure are deployed using CI/CD techniques that ensure
            that changes are thouroughly tested and deployed with zero downtime.
            Where sensitive details are stored, eg. bank details, state of the
            art key management techniques are used to protect sensitive data in
            line with requirements such as the Payments Card Industry (PCI)
            Standard
          </p>
        </div>
      </div>
      <img className="img-fluid" src="./backgroundsm.png" alt="background" />
    </div>
  );
};

export default Home;
