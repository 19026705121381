import React from "react";

const CaseStudy: React.FC = () => {
  return (
    <div
      className="container-fluid"
      style={{ paddingRight: "10%", paddingLeft: "10%" }}
    >
      <div
        className="border"
        style={{
          padding: "2%",
          minHeight: "50vh",
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          backgroundColor: "white"
        }}
      >
        <div>
          <img src="./post-office-logo-small.png" alt="SCP logo" width="122px" height="92px" />
          <hr />
          <div>
            <p>
              Cloud Development Partner has worked with the UK Post Office since 2020 to design and develop a system to replace the
              Horizon Point of Sale system used in every Post Office.  The system is Cloud Native running on AWS. It is being piloted in
              Post offices around the country prior to a large scale roll out. The new system is a major improvement on Horizon and has
              been very warmly welcomed by the Post Master Community.
            </p>
          </div>
        </div>
        <br></br>
        <div>
          <img src="./SCPlogo.png" alt="SCP logo" width="200px" height="42px" />
          <hr />
          <div>
            <p>
              Cloud Development Partner has designed and manged the build of a
              Cloud Native P2PE payment platform for SureCanPay. The solution is
              a multi-tenanted platform for managing integrated payments systems
              (EPOS) with low cost PIN Entry devices. The solution reduces the
              overhead of PCI by using sophisticated key management techniques
              to ensure that card holder data is protected. The payments engine
              has a real time component which is written in Golang and hosted on
              the Amazon Elastic Container Service (ECS). There are batch
              processes which use AWS batch and are scheduled by Cloudwatch
              events. Device authentication is managed by the AWS IoT framework
              and User Authentication by AWS Cognito.
            </p>
            <p>
              The platform provide API access using Amazon API Gateway and a web
              portal.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudy;
